@font-face {
    font-family: notosans;
    src: url(/static/media/NotoSansKR-Regular.913f146b.otf);
}

@font-face {
    font-family: notosansbold;
    src: url(/static/media/NotoSansKR-Bold.b59ac7cf.otf);
}

@font-face {
    font-family: notosansmedium;
    src: url(/static/media/NotoSansKR-Medium.32666ae3.otf);
}

body {
    margin: 0;
    font-family: 'notosans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.active-menu {
    background-color: white !important;
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
    padding: 10px 40px;
    border-radius: 10px;
    color: #221e1f!important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body::-webkit-scrollbar {
    display: none;
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: #221e1f;
}

button {
    cursor: pointer;
}

