@font-face {
    font-family: notosans;
    src: url(../asset/font/NotoSansKR-Regular.otf);
}

@font-face {
    font-family: notosansbold;
    src: url(../asset/font/NotoSansKR-Bold.otf);
}

@font-face {
    font-family: notosansmedium;
    src: url(../asset/font/NotoSansKR-Medium.otf);
}

body {
    margin: 0;
    font-family: 'notosans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.active-menu {
    background-color: white !important;
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
    padding: 10px 40px;
    border-radius: 10px;
    color: #221e1f!important;
}
